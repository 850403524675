
import PDEIcon from '@/components/pde/PDEIcon.vue'

export default {
  name: 'EditMessage',
  components: {
    PDEIcon,
  },
  props: {
    editMessage: {
      type: Object,
      default: () => ({
        message: '',
        type: '',
        class: 'success',
      }),
    },
  },
  data() {
    return {
      showMessage: false,
      icons: {
        success: {
          icon: 'checkmark',
          textColor: 'text-success-700',
        },
        'success-circled': {
          icon: 'checkbox-circle-checked',
          textColor: 'text-success-700',
        },
        error: {
          icon: 'error-circle',
          textColor: 'text-error-800',
        },
      },
    }
  },
  computed: {
    getIconName() {
      return this.icons?.[this.editMessage.class]?.icon
    },
    getIconClasses() {
      return [this.icons?.[this.editMessage.class]?.textColor]
    },
  },
  watch: {
    editMessage(val) {
      if (val.type && (val.type === 'success' || val.type === 'error')) {
        this.showMessage = true

        // Hiding the message after 5 seconds
        setTimeout(() => {
          this.showMessage = false
        }, 5000)
      }
    },
  },
}


import { mapGetters, mapActions, mapState } from 'vuex'
import PDETooltip from '@/components/pde/PDETooltip.vue'
import PDEIcon from '@/components/pde/PDEIcon.vue'
import PDEToggle from '@/components/pde/PDEToggle.vue'

export default {
  name: 'AailableToWorkToggle',
  components: {
    PDEToggle,
    PDEIcon,
    PDETooltip,
  },
  props: {
    tooltipPosition: {
      type: String,
      default: 'left',
      validator: value => ['left', 'right', 'above', 'below'].includes(value),
    },
  },
  computed: {
    ...mapGetters('account', [
      'primarySpecialty',
      'providerRep',
      'specialtyDetails',
      'availableToWork',
    ]),
    ...mapGetters('identity', ['loggedInAsRep']),
    ...mapState('account', ['pendingRequestInFlight']),

  },
  methods: {
    ...mapActions('analytics', ['sendClickEvent']),
    ...mapActions('account', ['setAvailableToWork']),
    ...mapActions('identity', ['setAvailableToWorkDialogDismissed']),
    ...mapActions('navigation', [
      'toggleAvailableToWorkModal',
    ]),

    async handleAvailableToWorkToggle(event) {
      this.sendClickEvent({
        name: 'Available To Work Toggle Click',
        text: event.target.checked ? 'On' : 'Off',
        area: 'header/profile-dropdown',
      })

      if (this.availableToWork) {
        this.toggleAvailableToWorkModal()
      } else {
        this.setAvailableToWorkDialogDismissed(true)
        await this.setAvailableToWork({ availableToWork: true })
      }
    },
  },
}

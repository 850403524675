
import getReadabilityClass from '@/mixins/getReadabilityClass/getReadabilityClass.js'
import PDEButton from '@/components/pde/PDEButton.vue'
import PDEHeading from '@/components/pde/PDEHeading.vue'
import PDEIcon from '@/components/pde/PDEIcon.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CommunicationPreferences',
  components: { PDEButton, PDEHeading, PDEIcon },
  mixins: [getReadabilityClass],
  computed: {
    ...mapGetters('identity', ['loggedInAsRep']),
    ...mapGetters('account', ['oneTrustUrl']),
  },
  methods: {
    ...mapActions('analytics', ['sendClickEvent']),

    handleEditButtonAnalytics() {
      this.sendClickEvent({
        name: 'Edit Preferences',
        text: 'Edit',
        area: 'communications/subscriptions',
      })
      window.open(this.oneTrustUrl, '_blank')
    },
  },

}

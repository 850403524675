import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled", "checked"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("label", {
    "data-test": "pde-toggle",
    class: _normalizeClass(["relative inline-block h-24 w-40 overflow-hidden rounded-24 z-0", [$options.cursorClasses]])
  }, [
    _createElementVNode("input", {
      disabled: $props.disabled || $props.busy,
      checked: $props.toggledOn,
      type: "checkbox",
      class: "pde-toggle-input hidden",
      onInput: _cache[0] || (_cache[0] = $event => (_ctx.$emit('input', $event)))
    }, null, 40, _hoisted_1),
    _createElementVNode("span", {
      class: _normalizeClass(["pde-toggle__track absolute inset-0 transition duration-200 w-double", [$options.trackClasses]])
    }, null, 2),
    _createElementVNode("span", {
      class: _normalizeClass(["absolute top-2 left-2 h-20 w-20 bg-pureblack-0 rounded-full transition duration-300 ease-in-out", [$options.switchClasses]])
    }, null, 2)
  ], 2))
}

import { mapActions, mapGetters } from 'vuex'
import UITextfield from '@/components/ui/forms/UITextfield/UITextfield.vue'
import PDEHeading from '@/components/pde/PDEHeading.vue'
import PDEButton from '@/components/pde/PDEButton.vue'
import { Form as VeeForm } from 'vee-validate'

export default {
  name: 'Email',

  components: {
    PDEButton,
    PDEHeading,
    UITextfield,
    VeeForm,
  },

  data: () => ({
    edit: false,
    email: '',
    newEmail: '',
  }),

  computed: {
    ...mapGetters('identity', ['userId', 'loginName', 'loggedInAsRep']),

    toggleLabel() {
      return this.edit ? 'Cancel' : 'Edit'
    },

    validationSchema() {
      return this.$toTypedSchema(this.$yup.object().shape({
        newEmail: this.$yup.string()
          .email(this.$t('global.validation.yup.email.valid'))
          .required(this.$t('global.validation.yup.email.required'))
          .notOneOf([this.loginName], this.$t('global.validation.yup.email.unique')),
      }))
    },
  },

  beforeMount() {
    this.email = this.loginName
  },

  methods: {
    ...mapActions('identity', ['changeUsername']),
    ...mapActions('analytics', ['sendClickEvent']),
    ...mapActions('toast', ['openToast']),

    toggleEdit() {
      if (!this.edit) {
        this.newEmail = ''
      }

      this.edit = !this.edit
    },

    async submitForm(values) {
      try {
        const savedEmail = await this.changeUsername({
          newEmail: values.newEmail.trim(),
          userId: this.userId,
        })

        this.openToast({
          content: 'Success. Your email address has been updated.',
          duration: 5000,
          type: 'success',
        })

        this.sendClickEvent({
          name: 'Email address change',
          text: 'success',
          area: 'preferences/email',
        })

        this.toggleEdit()
        this.email = savedEmail
      } catch (e) {
        this.openToast({
          content: 'Could not update your email address, please try again later.',
          duration: 5000,
          type: 'error',
        })

        this.sendClickEvent({
          name: 'Email address change',
          text: 'failure',
          area: 'preferences/email',
        })
      }
    },
  },
}

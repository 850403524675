
import { mapActions, mapGetters } from 'vuex'
import PDEToggle from '@/components/pde/PDEToggle.vue'
import PDEConfirmationModal from '@/components/pde/PDEConfirmationModal.vue'
import PDEHeading from '@/components/pde/PDEHeading.vue'

export default {
  $_veeValidate: {
    validator: 'new', // This component validates children
  },

  name: 'TwoStepVerification',

  components: {
    PDEHeading,
    PDEToggle,
    PDEConfirmationModal,
  },

  data() {
    return {
      mfaToggleLoading: false,
    }
  },

  computed: {
    ...mapGetters('identity', ['userId', 'loginName', 'mfaEnabled', 'loggedInAsRep']),
    ...mapGetters('navigation', ['twoStepVerificationModalOpen']),
  },

  async beforeMount() {
    if (this.twoStepVerificationModalOpen) {
      this.closeTwoStepVerificationModal()
    }

    this.mfaToggleLoading = true
    await this.getMfaStatus()
    this.mfaToggleLoading = false
  },
  methods: {
    ...mapActions('identity', ['getMfaStatus', 'enableMfa', 'disableMfa']),
    ...mapActions('navigation', ['openTwoStepVerificationModal', 'closeTwoStepVerificationModal']),
    ...mapActions('analytics', ['sendClickEvent']),

    async handleTwoStepVerification() {
      const aaOptions = {
        name: 'Two step verification ',
        area: 'AccountPreferences/Security',
      }

      this.mfaToggleLoading = true

      if (!this.mfaEnabled) {
        await this.enableMfa()
        aaOptions.name += 'toggle Click'
        aaOptions.text = 'Turn On'
      } else {
        this.openTwoStepVerificationModal()
        aaOptions.name += 'toggle confirmation modal'
        aaOptions.text = 'Open Confirmation Modal'
      }
      this.mfaToggleLoading = false
      this.sendClickEvent(aaOptions)
    },

    handleCancelDialogButton() {
      this.closeTwoStepVerificationModal()
    },

    async handleConfirmDialogButton() {
      const aaOptions = {
        name: 'Two step verification off Click',
        area: 'AccountPreferences/Security',
        text: 'Yes Turn Off',
      }
      this.mfaToggleLoading = true
      this.closeTwoStepVerificationModal()
      await this.disableMfa()
      this.mfaToggleLoading = false
      this.sendClickEvent(aaOptions)
    },
  },
}


import LogRocket from 'logrocket'
import { mapActions, mapGetters } from 'vuex'
import UITextfield from '@/components/ui/forms/UITextfield/UITextfield.vue'
import UIPasswordValidator from '@/components/ui/forms/UIPasswordValidator/UIPasswordValidator.vue'
import PDEHeading from '@/components/pde/PDEHeading.vue'
import PDEButton from '@/components/pde/PDEButton.vue'
import { Form as VeeForm } from 'vee-validate'

export default {
  name: 'Password',

  components: {
    PDEHeading,
    UIPasswordValidator,
    UITextfield,
    PDEButton,
    VeeForm,
  },

  data() {
    return {
      edit: false,
    }
  },
  computed: {
    ...mapGetters('identity', ['loggedInAsRep']),

    validationSchema() {
      return this.$toTypedSchema(this.$yup.object().shape({
        currentPassword: this.$yup.string()
          .required(this.$t('global.validation.yup.currentPassword.required'))
          .label(this.$t('global.validation.yup.currentPassword.label')),
        newPassword: this.$yup.string()
          .required(this.$t('global.validation.yup.newPassword.required'))
          .label(this.$t('global.validation.yup.newPassword.label'))
          .matches(/^(?=.*[a-z])(?=.*[0-9])(.{8,})$/, this.$t('global.validation.yup.newPassword.regex'))
          .notOneOf([this.$yup.ref('currentPassword')], this.$t('global.validation.yup.newPassword.sameAsCurrent')),
      }))
    },
    newPasswordRules() {
      return {
        required: true,
        regex: /^(?=.*[0-9])(?=.*[a-z])(.{8,})$/,
        is_not: this.currentPassword,
      }
    },
    toggleLabel() {
      return this.edit
        ? 'Cancel'
        : 'Edit'
    },
  },

  methods: {
    ...mapActions('analytics', ['sendClickEvent']),
    ...mapActions('toast', ['openToast']),

    toggleEdit(resetFormFn) {
      this.edit = !this.edit
      resetFormFn()
    },

    async submitForm(values, actions) {
      try {
        await this.$store.dispatch('account/changePassword', {
          oldPassword: values.currentPassword,
          newPassword: values.newPassword,
        })
        this.toggleEdit(actions.resetForm)

        this.openToast({
          content: 'Success. Your password has been updated.',
          duration: 5000,
          type: 'success',
        })
        this.sendClickEvent({
          name: 'Password change',
          text: 'success',
          area: 'preferences/password',
        })
      } catch (error) {
        if (error?.response?.data?.message?.includes('E0000014')) {
          actions.setFieldError('currentPassword', 'Your current password is incorrect')
        } else {
          this.openToast({
            content: 'We\'re currently having issues updating your password. Please try again later.',
            duration: 5000,
            type: 'error',
          })

          LogRocket.captureException(error)
        }

        this.sendClickEvent({
          name: 'Password change',
          text: 'failure',
          area: 'preferences/password',
        })
      }
    },
  },
}
